import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Menu } from "./Menu";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

export function UpdateCartera() {
  const {
    id,
    con,
    fei,
    fet,
    inc,
    can,
    arr,
    mpa,
    varr,
    fpa,
    fec,
    est,
    desc,
    pre,
  } = useParams();
  const ident = id.substring(3);
  const contrato = con.substring(4);

  const fechainicio = fei.substring(4);
  const fechaterminacion = fet.substring(4);
  const incremento = inc.substring(4);
  const canon = can.substring(4);
  const arrendador = arr.substring(4);
  const mediopago = mpa.substring(4);
  const valorarriendo = varr.substring(5);
  const fechapago = fpa.substring(4);
  const fecha = fec.substring(4);
  const estad = est.substring(4);
  const descripcion = desc.substring(5);
  const preaviso = pre.substring(5);
  const navigate = useNavigate(ident);

  const setCarteravalues = {
    contrato: contrato,
    fechainicio: fechainicio,
    fechaterminacion: fechaterminacion,
    incremento: incremento,
    canon: canon,
    arrendador: arrendador,
    mediopago: mediopago,
    valorarriendo: valorarriendo,
    fechapago: fechapago,
    fecha: fecha,
    estado: estad,
    descripcion: descripcion,
    preaviso: preaviso,
  };

  //Useform

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [cartera, setCartera] = useState(setCarteravalues);
  const [estado, setEstado] = useState(setCarteravalues.estado);

  const onSubmit = handleSubmit((data) => {
    console.log("entre al submit");
    updateRegister(
      ident,
      data.contrato,
      data.fechainicio,
      data.fechaterminacion,
      data.incremento,
      data.canon,
      data.arrendador,
      data.mediopago,
      data.valorarriendo,
      data.fechapago,
      data.fecha,
      data.estado,
      data.descripcion,
      data.preaviso
    );
  });
  const queryregisterid = async (id) => {
    const docRef = doc(db, "cartera", ident);
    const docSnap = await getDoc(docRef);
    setCartera({ ...docSnap.data() });
  };

  const updateRegister = async (
    id,
    contrato,
    fechainicio,
    fechaterminacion,
    incremento,
    canon,
    arrendador,
    mediopago,
    valorarriendo,
    fechapago,
    fecha,
    estado,
    descripcion,
    preaviso
  ) => {
    const carteraRef = doc(db, "cartera", id);
    await updateDoc(carteraRef, {
      "data.contrato": contrato,
      "data.fechainicio": fechainicio,
      "data.fechaterminacion": fechaterminacion,
      "data.incremento": incremento,
      "data.canon": canon,
      "data.arrendador": arrendador,
      "data.mediopago": mediopago,
      "data.valorarriendo": valorarriendo,
      "data.fechapago": fechapago,
      "data.fecha": fecha,
      "data.estado": estado,
      "data.descripcion": descripcion,
      "data.preaviso": preaviso,
    });
    toast("Cartera actualizado con exito", {
      type: "success",
    });
    navigate("/Clientes");
  };

  useEffect(() => {
    queryregisterid(ident);
    setCartera({ ...cartera });
  }, [ident]);

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8 rounded-lg"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                  value={cartera.contrato}
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechainicio"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de inicio
                </label>
                <input
                  type="date"
                  {...register("fechainicio", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Inicio"
                  value={cartera.fechainicio}
                />
                {errors.fechainicio?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de terminación
                </label>
                <input
                  type="date"
                  {...register("fechaterminacion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Terminación"
                  value={cartera.fechaterminacion}
                />
                {errors.fechaterminacion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="mediopago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Medio de pago
                </label>
                <select
                  {...register("mediopago")}
                  value={cartera.mediopago}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="efectivo">Efectivo</option>
                  <option value="consignacion">Consignacion</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="valorarriendo"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Valor Arriendo
                </label>
                <input
                  type="text"
                  {...register("valorarriendo", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor Arriendo"
                  value={cartera.valorarriendo}
                />
                {errors.valorarriendo?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Pago
                </label>
                <select
                  {...register("estado")}
                  value={cartera.estado}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="yes">Pagado</option>
                  <option value="no">No pagado</option>
                </select>
                {/* <input
                  name="estado"
                  type="checkbox"
                  onChange={(e) => {
                    setValue("estado", e.target.checked ? "yes" : "no");
                    setEstado(!estado);
                    console.log("estado actual", estado);
                  }}
                  checked={estado}
                  placeholder="Descripción de la cartera"
                  className="shadow w-6 h-6 rounded"
                  value={cartera.estado}
                /> */}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="incremento"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Incremento
                </label>
                <input
                  type="number"
                  {...register("incremento", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Incremento"
                  value={cartera.incremento}
                />
                {errors.incremento?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="canon"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Canon
                </label>
                <input
                  type="number"
                  {...register("canon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Canon"
                  value={cartera.canon}
                />
                {errors.canon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Arrendador"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                  value={cartera.arrendador}
                />
                {errors.arrendador?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="fechapago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de pago
                </label>
                <input
                  type="date"
                  {...register("fechapago", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha de pago"
                  value={cartera.fechapago}
                />
                {errors.fechapago?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha"
                  value={cartera.fecha}
                />
                {errors.fecha?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="text"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Descripción
                </label>
                <input
                  type="text"
                  {...register("descripcion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Descripción de la cartera"
                  value={cartera.descripcion}
                />
                {errors.descripcion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Actualizar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Cartera"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
