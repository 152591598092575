import { useState } from "react";
import { useAuth } from "../context/authContex";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "./Alert";

export function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login, loginwithgoogle, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handlechange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await login(user.email, user.password);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      await loginwithgoogle();
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async () => {
    if (!user.email) return setError("Por favor ingresa tu email");
    try {
      await resetPassword(user.email);
      setError(
        "Te hemos enviado un correo a tu bandeja de entrada para que resetees tu contraseña"
      );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="p-4 w-full max-w-xs m-auto text-white md:p-0 ">
      <div className="">
        <h1 className="text-3xl font-serif text-center font-bold text-white mt-4 mb-4">
          Inmobiliaria
        </h1>
        <h3 className="text-xl font-mono text-center font-bold text-white mt-4 mb-4">
          Fernando Gomez e Hijos
        </h3>
      </div>

      {error && <Alert message={error} />}
      <form
        onSubmit={handlesubmit}
        className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="yourmail@company.com"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
            onChange={handlechange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            name="password"
            placeholder="******"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
            onChange={handlechange}
          />
        </div>

        <div className="md:flex md:items-center md:justify-between">
          <button className="bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Ingresar
          </button>
          <a
            href="#!"
            className="py-4 md:py-0 inline-block align-baseline font-bold text-sm text-teal-600 hover:text-teal-400"
            onClick={handleResetPassword}
          >
            Olvidé mi Contraseña
          </a>
        </div>
      </form>

      <p className="my-4 text-sm flex justify-between px-3">
        ¡No tienes una cuenta! <Link to="/Register">Registrarse</Link>
      </p>

      {/**<button
        onClick={handleGoogleSignin}
        className="bg-teal-600 hover:bg-teal-400 text-white text-bold shadow-md rounded border-2 border-teal-600 py-2 px-4 w-full"
      >
        Registrarse con Google
      </button> */}
    </div>
  );
}
