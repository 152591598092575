import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu } from "./Menu";
import { addcartera } from "./Addregister";
import { useForm } from "react-hook-form";

export function AltaCartera() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  console.log(errors);

  const setCarteraValues = {
    contrato: "",
    fechainicio: "",
    fechaterminacion: "",
    incremento: "",
    canon: "",
    arrendador: "",
    mediopago: "",
    valorarriendo: "",
    fechapago: "",
    fecha: "",
    descripcion: "",
    estado: "",
  };

  const [cartera, setCartera] = useState(setCarteraValues);
  const [estado, setEstado] = useState(false);

  const onSubmit = handleSubmit((data) => {
    setCartera(data);
    addcartera(data);
    reset();
  });

  return (
    <div>
      <Menu />

      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8 rounded-lg"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechainicio"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de inicio
                </label>
                <input
                  type="date"
                  {...register("fechainicio", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Inicio"
                />
                {errors.fechainicio?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de terminación
                </label>
                <input
                  type="date"
                  {...register("fechaterminacion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Terminación"
                />
                {errors.fechaterminacion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="mediopago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Medio de pago
                </label>
                <input
                  type="text"
                  {...register("mediopago", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Medio de pago"
                />
                {errors.mediopago?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="valorarriendo"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Valor Arriendo
                </label>
                <input
                  type="text"
                  {...register("valorarriendo", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor Arriendo"
                />
                {errors.valorarriendo?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Pago
                </label>
                <select
                  {...register("estado")}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="yes">Pagado</option>
                  <option value="no">No pagado</option>
                </select>
                {/* <input
                  name="estado"
                  type="checkbox"
                  onChange={(e) => {
                    setValue("estado", e.target.checked ? "pago" : "pendiente");
                    setEstado(!estado);
                  }}
                  checked={estado}
                  placeholder="Descripción de la cartera"
                  className="shadow w-6 h-6 rounded"
                /> */}
                {errors.estado?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="incremento"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Incremento
                </label>
                <input
                  type="number"
                  {...register("incremento", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Incremento"
                />
                {errors.incremento?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="canon"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Canon
                </label>
                <input
                  type="number"
                  {...register("canon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Canon"
                />
                {errors.canon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Arrendador"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                />
                {errors.arrendador?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="fechapago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de pago
                </label>
                <input
                  type="date"
                  {...register("fechapago", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha de pago"
                />
                {errors.fechapago?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha"
                />
                {errors.fecha?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Descripción
                </label>
                <input
                  type="text"
                  {...register("descripcion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Descripción de la cartera"
                />
                {errors.descripcion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Guardar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Cartera"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
