import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Menu } from "./Menu";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

export function UpdateClientes() {
  const { id, docu, con, nom, dir, cor, cel, fij, ciu } = useParams();
  const ident = id.substring(3);
  // console.log("id", ident);
  const contrato = con.substring(4);
  // console.log("Contrato", contrato);
  const documento = docu.substring(4);
  //console.log(documento);
  const nombre = nom.substring(4);
  // console.log("nombre", nombre);
  const direccion = dir.substring(4);
  // console.log("direcion", direccion);
  const correo = cor.substring(4);
  // console.log("correo", correo);
  const celular = cel.substring(4);
  // console.log("celular", celular);
  const fijo = fij.substring(4);
  //console.log("fijo", fijo);
  const ciudad = ciu.substring(4);
  // console.log("ciudad", ciudad);

  const navigate = useNavigate(ident);

  const setClientesvalues = {
    contrato: contrato,
    documento: documento,
    nombre: nombre,
    direccion: direccion,
    correo: correo,
    celular: celular,
    fijo: fijo,
    ciudad: ciudad,
  };

  //Useform

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [clientes, setClientes] = useState(setClientesvalues);

  const onSubmit = handleSubmit((data) => {
    console.log("data", data);
    updateRegister(
      ident,
      data.contrato,
      data.documento,
      data.nombre,
      data.direccion,
      data.correo,
      data.celular,
      data.fijo,
      data.ciudad
    );
  });
  const queryregisterid = async (id) => {
    const docRef = doc(db, "clientes", ident);
    const docSnap = await getDoc(docRef);
    setClientes({ ...docSnap.data() });
  };

  const updateRegister = async (
    id,
    contrato,
    documento,
    nombre,
    direccion,
    correo,
    celular,
    fijo,
    ciudad
  ) => {
    const clientesRef = doc(db, "clientes", id);
    await updateDoc(clientesRef, {
      "data.documento": documento,
      "data.contrato": contrato,
      "data.nombre": nombre,
      "data.direccion": direccion,
      "data.correo": correo,
      "data.celular": celular,
      "data.fijo": fijo,
      "data.ciudad": ciudad,
    });
    toast("Cliente actualizado con exito", {
      type: "success",
    });
    navigate("/Clientes");
  };

  useEffect(() => {
    queryregisterid(ident);
    setClientes({ ...clientes });
  }, [ident]);

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", { required: true })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Nombre"
                  value={clientes.contrato}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="documento"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Cédula o NIT
                </label>
                <input
                  type="text"
                  {...register("documento", {
                    required: true,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Cédula o NIT"
                  value={clientes.documento}
                />
                {errors.documento && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  {...register("nombre", { required: true })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Nombre"
                  value={clientes.nombre}
                />
                {errors.nombre && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="direccion"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  {...register("direccion", {
                    required: true,
                    pattern: /^[^#]*$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Dirección"
                  value={clientes.direccion}
                />
                {errors.direccion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.direccion?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    No se permite el caracter # reemplacelo por un N
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="celular"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Celular
                </label>
                <input
                  type="number"
                  {...register("celular", {
                    required: true,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Celular"
                  value={clientes.celular}
                />
                {errors.celular?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.celular?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 10 caracteres
                  </span>
                )}
                {errors.celular?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 10 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fijo"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Número fijo
                </label>
                <input
                  type="number"
                  {...register("fijo", {
                    required: true,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Número fijo"
                  value={clientes.fijo}
                />
                {errors.fijo?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.fijo?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 7 caracteres
                  </span>
                )}
                {errors.fijo?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 7 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="ciudad"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Ciudad
                </label>
                <input
                  type="text"
                  {...register("ciudad", {
                    required: true,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Ficha catastral"
                  value={clientes.ciudad}
                />
                {errors.ciudad && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Correo
                </label>
                <input
                  type="email"
                  {...register("correo", {
                    required: true,
                    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Correo electronico"
                  value={clientes.correo}
                />
                {errors.correo?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.correo?.types === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    El correo no es valido
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Actualizar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Clientes"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
