import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Menu } from "./Menu";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

export function UpdateContratos() {
  const {
    id,
    con,
    arr,
    can,
    iva,
    mpa,
    est,
    adm,
    iadm,
    icr,
    fec,
    fet,
    fpa,
    desc,
    pre,
  } = useParams();
  const ident = id.substring(3);
  console.log(ident);
  const contrato = con.substring(4);
  console.log(contrato);
  const arrendador = arr.substring(4);
  console.log(arrendador);
  const canon = can.substring(4);
  console.log(canon);
  const valoriva = iva.substring(4);
  console.log(valoriva);
  const mediopago = mpa.substring(4);
  console.log(mediopago);
  const estado = est.substring(4);
  console.log(estado);
  const valoradministracion = adm.substring(4);
  console.log(valoradministracion);

  const incrementoadmon = iadm.substring(5);
  console.log(incrementoadmon);
  const incremento = icr.substring(4);
  console.log(incremento);
  const fecha = fec.substring(4);
  console.log(fecha);
  const fechaterminacion = fet.substring(4);
  console.log(fechaterminacion);
  const fechapago = fpa.substring(4);
  console.log(fechapago);

  const descripcion = desc.substring(5);
  console.log(descripcion);

  const preaviso = pre.substring(4);
  console.log(preaviso);
  const navigate = useNavigate(ident);

  const setContratosvalues = {
    contrato: contrato,
    arrendador: arrendador,
    canon: canon,
    valoriva: valoriva,
    mediopago: mediopago,
    estado: estado,
    valoradministracion: valoradministracion,
    incrementoadmon: incrementoadmon,
    incremento: incremento,
    fecha: fecha,
    fechaterminacion: fechaterminacion,
    fechapago: fechapago,
    descripcion: descripcion,
    preaviso: preaviso,
  };

  //Useform

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [contratos, setContratos] = useState(setContratosvalues);

  const onSubmit = handleSubmit((data) => {
    updateRegister(
      ident,
      data.contrato,
      data.arrendador,
      data.canon,
      data.valoriva,
      data.mediopago,
      data.estado,
      data.valoradministracion,
      data.incrementoadmon,
      data.incremento,
      data.fecha,
      data.fechaterminacion,
      data.fechapago,
      data.descripcion,
      data.preaviso
    );
  });
  const queryregisterid = async (id) => {
    const docRef = doc(db, "contratos", ident);
    const docSnap = await getDoc(docRef);
    setContratos({ ...docSnap.data() });
  };

  const updateRegister = async (
    id,
    contrato,
    arrendador,
    canon,
    valoriva,
    mediopago,
    estado,
    valoradministracion,
    incrementoadmon,
    incremento,
    fecha,
    fechaterminacion,
    fechapago,
    descripcion,
    preaviso
  ) => {
    const contratosRef = doc(db, "contratos", id);
    await updateDoc(contratosRef, {
      "data.contrato": contrato,
      "data.arrendador": arrendador,
      "data.canon": canon,
      "data.valoriva": valoriva,
      "data.mediopago": mediopago,
      "data.estado": estado,
      "data.valoradministracion": valoradministracion,
      "data.incrementoadmon": incrementoadmon,
      "data.incremento": incremento,
      "data.fecha": fecha,
      "data.fechaterminacion": fechaterminacion,
      "data.fechapago": fechapago,
      "data.descripcion": descripcion,
      "data.preaviso": preaviso,
    });
    toast("Contrato actualizado con exito", {
      type: "success",
    });

    navigate("/Contratos");
  };

  useEffect(() => {
    queryregisterid(ident);
    setContratos({ ...contratos });
  }, [ident]);

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                  value={contratos.contrato}
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="arrendador"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                  value={contratos.arrendador}
                />
                {errors.arrendador && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="canon"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Canón
                </label>
                <input
                  type="number"
                  {...register("canon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Canon"
                  value={contratos.canon}
                />
                {errors.canon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="valoriva"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Valor IVA
                </label>
                <input
                  type="number"
                  {...register("valoriva", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor IVA"
                  value={contratos.valoriva}
                />
                {errors.valoriva?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="mediopago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Medio de pago
                </label>
                <select
                  {...register("mediopago")}
                  value={contratos.mediopago}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="efectivo">Efectivo</option>
                  <option value="consignacion">Consignacion</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Estado de pago
                </label>
                <select
                  {...register("estado")}
                  value={contratos.estado}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="yes">Pagado</option>
                  <option value="no">No pagado</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="descripcion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Descripción
                </label>
                <input
                  type="text"
                  {...register("descripcion", {})}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Descripcion"
                  value={contratos.descripcion}
                />
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="valoradministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Valor de administración
                </label>
                <input
                  type="number"
                  {...register("valoradministracion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor de administración"
                  value={contratos.valoradministracion}
                />
                {errors.valoradministracion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incrementoadministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  IPC
                </label>
                <input
                  type="text"
                  {...register("incrementoadmon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Incremento administración"
                  value={contratos.incrementoadmon}
                />
                {errors.incrementoadmon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incremento"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  PUNTOS
                </label>
                <input
                  type="text"
                  {...register("incremento", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Incremento"
                  value={contratos.incremento}
                />
                {errors.incremento?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incrementoadministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Preaviso
                </label>
                <input
                  type="text"
                  {...register("preaviso", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Preaviso"
                  value={contratos.preaviso}
                />
                {errors.preaviso?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha Inicio
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha"
                  value={contratos.fecha}
                />
                {errors.fecha && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha Terminación
                </label>
                <input
                  type="date"
                  {...register("fechaterminacion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Terminación"
                  value={contratos.fechaterminacion}
                />
                {errors.fechaterminacion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de pago
                </label>
                <input
                  type="date"
                  {...register("fechapago", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha de pago"
                  value={contratos.fechapago}
                />
                {errors.fec?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Actualizar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Contratos "}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
