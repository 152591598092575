import { useAuth } from "../context/authContex";

import { Statistic } from "./Statistic";

export function Home() {
  const { user, logout, loading } = useAuth();
  console.log(user);
  const handlelogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex justify-end items-center p-4">
        <button
          onClick={handlelogout}
          className="w-44 h-10 bg-teal-600 rounded-full hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
        >
          Cerrar Sesion
        </button>
      </div>
      <Statistic />
    </div>
  );
}
