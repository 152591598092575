import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect } from "react";

const { formatDistance, formatDistanceStrict } = require("date-fns");

export const Statistic = () => {
  const [contratos, setContratos] = useState([]);

  const fechaactual = new Date();

  const restarFecha = (fecha) => {
    const fechanueva = new Date(fecha);

    //restar los cinco dias
    fechanueva.setDate(fechanueva.getDate() - 5);
    return fechanueva;
  };

  const ViewRegister = async () => {
    const q = query(collection(db, "contratos"));
    onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        const fechaterminacion = doc
          .data()
          .data.fechaterminacion.replaceAll("-", "/");

        //formato de la fechaterminacion
        const fechaterminacioncasteada = new Date(fechaterminacion);
        // llamó la función de restar los 5 dias
        const fechaNueva = restarFecha(fechaterminacion);
        // comparacion de fechas para determinar si esta en el rango del 5 dias la fecha actual.

        const result = formatDistance(
          new Date(fechaterminacion),
          new Date(fechaactual)
        );
        console.log("formatDistance", result);

        switch (result) {
          case "5 days":
            docs.push({ ...doc.data(), id: doc.id });
            break;
          case "4 days":
            docs.push({ ...doc.data(), id: doc.id });
            break;
          case "3 days":
            docs.push({ ...doc.data(), id: doc.id });
            break;
          case "2 days":
            docs.push({ ...doc.data(), id: doc.id });
            break;
          case "1 day":
            docs.push({ ...doc.data(), id: doc.id });
            break;
        }
      });
      setContratos(docs);
      console.log("contratos.length", contratos.length);
    });
  };

  useEffect(() => {
    ViewRegister();
  }, []);

  return (
    <div className="flex justify-center">
      <div className={`${contratos.length !== 0 ? "block" : "hidden"}`}>
        {contratos.map((con) => (
          <div className="text-center" key={con.id}>
            <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-teal-400 sm:w-12 sm:h-12">
              <svg
                className="w-8 h-8 text-teal-900 sm:w-10 sm:h-10"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
            <span className="p-2 text-normal font-sans font-bold text-red-800">
              Este contrato está proximo a vencer.
            </span>
            <div className="flex flex-col">
              <span className="p-2 text-4xl font-bold font-sans text-violet-800">
                Contrato
              </span>
              <h6 className="text-4xl font-bold text-deep-purple-accent-400">
                {con.data.contrato}
              </h6>
            </div>

            <p className="mb-2 font-bold text-md text-violet-800">
              Fecha de Terminación de contrato.
            </p>
            <p className="text-gray-700 font-bold ">
              {con.data.fechaterminacion}
            </p>
          </div>
        ))}
      </div>
      <div className={`${contratos.length !== 0 ? "hidden" : "block"}`}>
        <div class="flex justify-center">
          <p class="font-sans text-3xl font-bold  text-gray-900">
            No existen Vencimientos
          </p>
        </div>
        <img className="" src="InboxEmpty.png" width="400px" />
      </div>
    </div>
  );
};
