import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu } from "./Menu";
import { Addregister } from "./Addregister";
import { useForm } from "react-hook-form";

export function AltaInmueble() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const setInmueblesvalues = {
    contrato: "",
    areapredio: "",
    direccion: "",
    evaluoanteior: "",
    evaluovigente: "",
    estrato: "",
    fichacatastral: "",
    matricula: "",
    matriculaagua: "",
    matriculaenergia: "",
    arrendador: "",
    participacion: "",
  };

  const [inmuebles, setInmuebles] = useState(setInmueblesvalues);
  // const handlechange = (e) => {
  //   const { name, value } = e.target;
  //   setInmuebles({ ...inmuebles, [name]: value });
  // };
  const onSubmit = handleSubmit((data) => {
    setInmuebles(data);
    console.log("data:", data);
    Addregister(data);
    reset();
  });

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8 rounded-lg"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="areapredio"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Area del predio
                </label>
                <input
                  type="text"
                  {...register("areapredio", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Area del predio"
                />
                {errors.areapredio && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="direccion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  {...register("direccion", {
                    required: true,
                    pattern: /^[^#]*$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Dirección"
                />
                {errors.direccion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.direccion?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    No se permite el caracter # reemplacelo por un N
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="evaluoanterio"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Evalúo comercial año anterior
                </label>
                <input
                  type="text"
                  {...register("evaluoanteior", {
                    required: true,
                    pattern: /^[0-9.]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Evalúo comercial año anterior"
                />
                {errors.evaluoanteior?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="evaluovigente"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Evalúo comercial año vigente
                </label>
                <input
                  type="text"
                  {...register("evaluovigente", {
                    required: true,
                    pattern: /^[0-9.]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Evalúo comercila año vigente"
                />
                {errors.evaluovigente?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="estrato"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Estrato
                </label>
                <input
                  type="number"
                  {...register("estrato", {
                    required: true,
                    maxLength: 1,
                    pattern: /^[0,1,2,3,4,5,6,7]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Estrato"
                />
                {errors.estrato?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.estrato?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permite un digito
                  </span>
                )}
                {errors.estrato?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permiten números del 0 al 7
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="fichacatastral"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Ficha catastral
                </label>
                <input
                  type="text"
                  {...register("fichacatastral", {
                    required: true,
                    minLength: 25,
                    maxLength: 25,
                    pattern: /^[A-Za-z0-9]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Ficha catastral"
                />
                {errors.fichacatastral?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.fichacatastral?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 25 caracteres
                  </span>
                )}
                {errors.fichacatastral?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener Máximo 25 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matricula"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Matricula
                </label>
                <input
                  type="text"
                  {...register("matricula", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                    pattern: /^[0-9-]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula"
                />
                {errors.matricula?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matricula?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matricula?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
                {errors.matricula?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permiten numeros y guiones medios
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matriculaagua"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Matricula Agua
                </label>
                <input
                  type="text"
                  {...register("matriculaagua", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                    pattern: /^[0-9-]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula Agua"
                />
                {errors.matriculaagua?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matriculaagua?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matriculaagua?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
                {errors.matriculaagua?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permiten numeros y guiones medios
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matriculaenergia"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Matricula Energia
                </label>
                <input
                  type="text"
                  {...register("matriculaenergia", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                    pattern: /^[0-9-]+$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula Energia"
                />
                {errors.matriculaenergia?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matriculaenergia?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matriculaenergia?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
                {errors.matriculaenergia?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permiten numeros y guiones medios
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="arrendador"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Nombre del arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                />
                {errors.arrendador?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Participación"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Participación
                </label>
                <input
                  type="texto"
                  {...register("participacion", {
                    required: true,
                    pattern: /^[^%]*$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Participación Ej:(50 - 50)"
                />
                {errors.participacion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.participacion?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    No se permite el caracter % sebe ingresar los valores
                    sepadores por un -
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Guardar
            </button>

            <Link
              className="w-44 bg-teal-600 flex justify-center hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Inmuebles"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
