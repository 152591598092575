import { Routes, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { Inmuebles } from "./components/Inmuebles";
import { Clientes } from "./components/Clientes";
import { Contratos } from "./components/Contratos";
import { Cartera } from "./components/Cartera";
import { Menu } from "./components/Menu";
import { Registers } from "./components/Registers";
import { AuthProvider } from "./context/authContex";
import { ProtectedRoute } from "./components/protectedRoute";
import { AltaInmueble } from "./components/AltaInmueble";
import { AltaCliente } from "./components/AltaCliente";
import { AltaContrato } from "./components/AltaContrato";
import { AltaCartera } from "./components/AltaCartera";
import { UpdateInmuebles } from "./components/UpdateInmuebles";
import { UpdateClientes } from "./components/UpdateClientes";
import { UpdateContratos } from "./components/UpdateContratos";
import { UpdateCartera } from "./components/UpdateCartera";
import { Addregister } from "./components/Addregister";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Statistic } from "./components/Statistic";
import { Anexos } from "./components/Anexos";

function App() {
  return (
    <div className="bg-cyan-900 flex w-full h-screen">
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <div className="grid grid-row-2">
                  <div className="hidden lg:block row-span-1">
                    <Menu />
                  </div>
                  <div className="lg:hidden row-span-1"></div>
                  <div className="row-span-11 bg-white">
                    <Home />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route path="/inmuebles" element={<Inmuebles />} />
          <Route path="/Register" element={<Registers />} />
          <Route path="/Clientes" element={<Clientes />} />
          <Route path="/Contratos" element={<Contratos />} />
          <Route path="/Cartera" element={<Cartera />} />
          <Route path="/Anexos" element={<Anexos />} />
          <Route path="/Register" element={<Registers />} />
          <Route path="/AltaInmueble" element={<AltaInmueble />} />
          <Route path="/AltaCliente" element={<AltaCliente />} />
          <Route path="/AltaContrato" element={<AltaContrato />} />
          <Route path="/AltaCartera" element={<AltaCartera />} />
          <Route path="/Statistic" element={<Statistic />} />

          <Route
            path="/UpdateInmuebles/:id/:ap/:dir/:ea/:ev/:es/:fc/:mat/:ma/:me/:cont/:arr/:part"
            element={<UpdateInmuebles />}
          />
          <Route
            path="/UpdateClientes/:id/:docu/:con/:nom/:dir/:cor/:cel/:fij/:ciu"
            element={<UpdateClientes />}
          />

          <Route
            path="/UpdateContratos/:id/:con/:arr/:can/:iva/:mpa/:est/:adm/:iadm/:icr/:fec/:fet/:fpa/:desc/:pre"
            element={<UpdateContratos />}
          />
          <Route
            path="/UpdateCartera/:id/:con/:fei/:fet/:inc/:can/:arr/:mpa/:varr/:fpa/:fec/:est/:desc/:pre"
            element={<UpdateCartera />}
          />
          <Route path="/Addregister" element={<Addregister />} />
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </div>
  );
}

export default App;
