import { useEffect, useState } from "react";
import React from "react";
import { Menu } from "./Menu";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import DataTable from "react-data-table-component";
import { useAuth } from "../context/authContex";
import {
  doc,
  deleteDoc,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Trash2, Pencil } from "lucide-react";

import { Plus } from "lucide-react";

export function Contratos() {
  const { user, logout, loading } = useAuth();
  console.log(user);
  const handlelogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const data = [];
  const [contratos, setContratos] = useState([]);
  const [record, setRecords] = useState(contratos);
  const [Currentid, setCurrentid] = useState("");
  const [id, setId] = useState();
  const [con, setIdc] = useState();
  const [arr, setArr] = useState();
  const [can, setCan] = useState();
  const [iva, setIva] = useState();
  const [mpa, setMpa] = useState();
  const [est, setEst] = useState();
  const [adm, setAdm] = useState();
  const [iadm, setIadm] = useState();
  const [icr, setIcr] = useState();
  const [fec, setFec] = useState();
  const [fet, setFt] = useState();
  const [fpa, setFap] = useState();
  const [desc, setDesc] = useState();
  const [pre, setPre] = useState();
  // const [data, setData] = useState();

  const navigate = useNavigate(Currentid);
  const Viewregister = async () => {
    const leng = data.length;
    console.log("leng", leng);
    if (leng < 0) {
      console.log("entre porque tengo datos");
      data = [];
    }

    const q = query(collection(db, "contratos"));
    onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setContratos(docs);
      setRecords(docs);

      docs.forEach((item) => {
        data.push({ ...item.data });
      });
      // setData(data);
      console.log("info el array data contratos :", data);
    });
  };

  const handleOnDelete = async ({ selectableRows }) => {
    console.log(selectableRows);

    if (window.confirm("Esta seguro que deseas eliminar el Contrato")) {
      console.log(id);
      await deleteDoc(doc(db, "contratos", id));
      toast("Contrato eliminado", {
        type: "error",
        autoClose: 1000,
      });
      navigate("/Contratos");
    }
  };

  const handleOnUpdate = (
    id,
    con,
    arr,
    can,
    iva,
    mpa,
    est,
    adm,
    iadm,
    icr,
    fec,
    fet,
    fpa,
    desc,
    pre
  ) => {
    setCurrentid(id);
    navigate(
      `/UpdateContratos/id:${id}/con:${con}/arr:${arr}/can:${can}/iva:${iva}/mpa:${mpa}/est:${est}/adm:${adm}/iadm:${iadm}/icr:${icr}/fec:${fec}/fet:${fet}/fpa:${fpa}/desc:${desc}/pre:${pre}`
    );
  };

  useEffect(() => {
    Viewregister();
  }, [Currentid]);

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(record[0].data);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    console.log("arraydownloadCSV", data);
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Contratos.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      onClick={(e) => onExport(data)}
    >
      Export
    </button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  const columns = [
    {
      name: "Contrato",
      selector: (row) => row.data.contrato,
      sortable: true,
      wrap: true,
    },
    {
      name: "Arrendador",
      selector: (row) => row.data.arrendador,
      sortable: true,
      wrap: true,
    },
    { name: "Canon", selector: (row) => row.data.canon, sortable: true },
    {
      name: "Valor IVA",
      selector: (row) => row.data.valoriva,
      sortable: true,
      wrap: true,
    },
    {
      name: "Medio de pago",
      selector: (row) => row.data.mediopago,
      sortable: true,
      wrap: true,
    },
    {
      name: "Estado pago",
      selector: (row) => row.data.estado,
      sortable: true,
      wrap: true,
    },
    {
      name: "Valor Administracion",
      selector: (row) => row.data.valoradministracion,
      sortable: true,
      wrap: true,
    },
    {
      name: "Incremento Admon",
      selector: (row) => row.data.incrementoadmon,
      sortable: true,
      wrap: true,
    },
    {
      name: "Incremento",
      selector: (row) => row.data.incremento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Fecha Inicio",
      selector: (row) => row.data.fecha,
      sortable: true,
      wrap: true,
    },
    {
      name: "Fecha Terminacion",
      selector: (row) => row.data.fechaterminacion,
      sortable: true,
      wrap: true,
    },
    {
      name: "Fecha Pago",
      selector: (row) => row.data.fechapago,
      sortable: true,
      wrap: true,
    },
    {
      name: "Descripcion",
      selector: (row) => row.data.descripcion,
      sortable: true,
      wrap: true,
    },
    {
      name: "Preaviso",
      selector: (row) => row.data.preaviso,
      sortable: true,
      wrap: true,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data

    selectedRows.map((row) => {
      setId(row.id);
      setIdc(row.data.contrato);
      setArr(row.data.arrendador);
      setCan(row.data.canon);
      setIva(row.data.valoriva);
      setMpa(row.data.mediopago);
      setEst(row.data.estado);
      setAdm(row.data.valoradministracion);
      setIadm(row.data.incrementoadmon);
      setIcr(row.data.incremento);
      setFec(row.data.fecha);
      setFt(row.data.fechaterminacion);
      setFap(row.data.fechapago);
      setDesc(row.data.descripcion);
      setPre(row.data.preaviso);
    });
  };
  const handleFilter = (event) => {
    const result = contratos.filter((row) => {
      return row.data.contrato
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRecords(result);
    //setCartera(result);
  };
  return (
    <div>
      <Menu />
      <div className="flex justify-end px-2"></div>
      <div className="mt-5">
        <div className="flex gap-4 p-4 ">
          <div className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            <i>
              <Plus />
            </i>
            <Link to={"/AltaContrato"}>Nuevo</Link>
          </div>

          <button
            className="mt-5 rounded-full cursor-pointer inline-flex flex gap-2 items-center bg-red-600 hover:bg-red-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnDelete(id);
            }}
            disabled={id === undefined}
          >
            <i>
              <Trash2 />
            </i>
            Eliminar
          </button>
          <button
            className="mt-5  rounded-full  inline-flex flex gap-2 items-center bg-blue-600 hover:bg-blue-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
            onClick={() => {
              handleOnUpdate(
                id,
                con,
                arr,
                can,
                iva,
                mpa,
                est,
                adm,
                iadm,
                icr,
                fec,
                fet,
                fpa,
                desc,
                pre
              );
            }}
            disabled={id === undefined}
          >
            <i>
              <Pencil />
            </i>
            Actualizar
          </button>
        </div>
        <div className="grid grid-cols-12">
          <div className="flex justify-start p-2 m-2 col-span-6 ">
            <input
              type="text"
              className="w-96 p-2 focus:outline-none  focus:shadow-outline border rounded "
              onChange={handleFilter}
              placeholder="Buscar por numero contrato"
            />
          </div>
          <div className="col-span-6 flex justify-end items-center p-4 ">
            <button
              onClick={handlelogout}
              className="w-44 h-10 bg-teal-600 rounded-full hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            >
              Cerrar Sesion
            </button>
          </div>
        </div>
        <div className="px-4">
          <DataTable
            selectableRows
            pagination
            onSelectedRowsChange={handleChange}
            columns={columns}
            data={record}
            fixedHeader
            title="Contratos"
            actions={actionsMemo}
          />
        </div>
      </div>
    </div>
  );
}
