import { useEffect, useState } from "react";
import React from "react";
import { Menu } from "./Menu";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import DataTable from "react-data-table-component";
import { useAuth } from "../context/authContex";
import {
  doc,
  deleteDoc,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Trash2, Pencil } from "lucide-react";

import { Plus } from "lucide-react";

export function Inmuebles() {
  const { user, logout, loading } = useAuth();
  console.log(user);
  const handlelogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const data = [];
  const [inmu, setInmu] = useState([]);
  const [Currentid, setCurrentid] = useState("");
  const [record, setRecords] = useState(inmu);

  //id
  const [id, setId] = useState();
  // areapredio
  const [ap, setAp] = useState();
  //direccion
  const [dir, setDir] = useState();
  //evaluo anterior
  const [ea, setEa] = useState();
  //evaluo vigente
  const [ev, setEv] = useState();
  //estrato
  const [es, setEs] = useState();
  //ficha catastral
  const [fc, setFc] = useState();
  //matricula
  const [mat, setMat] = useState();
  //matricula agua
  const [ma, setMa] = useState();
  //matricula energia
  const [me, setMe] = useState();
  //matricula servicio
  const [arr, setArr] = useState();
  //participacion
  const [part, setPart] = useState();
  //contrato
  const [cont, setCont] = useState();

  const navigate = useNavigate(Currentid);
  const Viewregister = async () => {
    const leng = data.length;
    console.log("leng", leng);
    if (leng < 0) {
      console.log("entre porque tengo datos");
      data = [];
    }

    const q = query(collection(db, "inmuebles"));
    onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setInmu(docs);
      setRecords(docs);

      docs.forEach((item) => {
        data.push(item.data);
      });
      console.log("info el array data Inmuebles :", data);
      //setData(data);
      //console.log("datas Inmuebles :", data);
    });
  };

  const handleOnDelete = async ({ selectableRows }) => {
    console.log(selectableRows);
    console.log("id", id);
    if (window.confirm("Esta seguro que deseas eliminar el Inmueble")) {
      console.log(id);
      await deleteDoc(doc(db, "inmuebles", id));
      toast("Inmueble eliminado", {
        type: "error",
        autoClose: 1000,
      });
      navigate("/Inmuebles");
    }
  };

  const handleOnUpdate = (
    id,
    ap,
    dir,
    ea,
    ev,
    es,
    fc,
    mat,
    ma,
    me,
    cont,
    arr,
    part
  ) => {
    setCurrentid(id);
    navigate(
      `/UpdateInmuebles/id:${id}/ap:${ap}/dir:${dir}/ea:${ea}/ev:${ev}/es:${es}/fc:${fc}/mat:${mat}/ma:${ma}/me:${me}/cont:${cont}/arr:${arr}/part:${part}`
    );
  };

  useEffect(() => {
    Viewregister();
  }, [Currentid]);

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;
    console.log("data convertArrayOfObjectsToCSV", array);
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    console.log("arraydownloadCSV", array);
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Inmuebles.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      onClick={(e) => onExport(data)}
    >
      Export
    </button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  const columns = [
    {
      name: "Contrato",
      selector: (row) => row.data.contrato,
      sortable: true,
      wrap: true,
    },
    {
      name: "Area",
      selector: (row) => row.data.areapredio,
      sortable: true,
      wrap: true,
    },
    {
      name: "Predio",
      selector: (row) => row.data.direccion,
      sortable: true,
      wrap: true,
    },
    { name: "Estrato", selector: (row) => row.data.estrato, sortable: true },
    {
      name: "Evaluo Anterior",
      selector: (row) => row.data.evaluoanteior,
      sortable: true,
      wrap: true,
    },
    {
      name: "Evaluo Vigente",
      selector: (row) => row.data.evaluovigente,
      sortable: true,
      wrap: true,
    },
    {
      name: "Fichacatastral",
      selector: (row) => row.data.fichacatastral,
      sortable: true,
      wrap: true,
    },
    {
      name: "Matricula",
      selector: (row) => row.data.matricula,
      sortable: true,
      wrap: true,
    },
    {
      name: "Matricula Agua",
      selector: (row) => row.data.matriculaagua,
      sortable: true,
      wrap: true,
    },
    {
      name: "Matricula Energia",
      selector: (row) => row.data.matriculaenergia,
      sortable: true,
      wrap: true,
    },
    {
      name: "Arrendador",
      selector: (row) => row.data.arrendador,
      sortable: true,
      wrap: true,
    },
    {
      name: "Participación",
      selector: (row) => row.data.participacion,
      sortable: true,
      wrap: true,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data

    selectedRows.map((row) => {
      setId(row.id);
      setAp(row.data.areapredio);
      setDir(row.data.direccion);
      setEa(row.data.evaluoanteior);
      setEv(row.data.evaluovigente);
      setEs(row.data.estrato);
      setFc(row.data.fichacatastral);
      setMat(row.data.matricula);
      setMa(row.data.matriculaagua);
      setMe(row.data.matriculaenergia);
      setArr(row.data.arrendador);
      setPart(row.data.participacion);
      setCont(row.data.contrato);
    });
  };
  const handleFilter = (event) => {
    const result = inmu.filter((row) => {
      return row.data.contrato
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    console.log("result", result);
    console.log("Inmu", inmu);
    setRecords(result);
    //setCartera(result);
  };
  return (
    <div>
      <Menu />

      <div className="flex flex-col">
        <div className="flex gap-4 p-4 ">
          <div className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            <i>
              <Plus />
            </i>
            <Link to={"/AltaInmueble"}>Nuevo</Link>
          </div>

          <button
            className="mt-5 cursor-pointer rounded-full  inline-flex flex gap-2 items-center bg-red-600 hover:bg-red-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnDelete(id);
            }}
            disabled={id === undefined}
          >
            <i>
              <Trash2 />
            </i>
            Eliminar
          </button>
          <button
            className="mt-5 rounded-full cursor-pointer inline-flex flex gap-2 items-center bg-blue-600 hover:bg-blue-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnUpdate(
                id,
                ap,
                dir,
                ea,
                ev,
                es,
                fc,
                mat,
                ma,
                me,
                cont,
                arr,
                part
              );
            }}
            disabled={id === undefined}
          >
            <i>
              <Pencil />
            </i>
            Actualizar
          </button>
        </div>
        <div className="grid grid-cols-12">
          <div className="flex justify-start p-2 m-2 col-span-6 ">
            <input
              type="text"
              className="w-96 p-2 focus:outline-none  focus:shadow-outline border rounded "
              onChange={handleFilter}
              placeholder="Buscar por numero contrato"
            />
          </div>
          <div className="col-span-6 flex justify-end items-center p-4 ">
            <button
              onClick={handlelogout}
              className="w-44 h-10 bg-teal-600 rounded-full hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            >
              Cerrar Sesion
            </button>
          </div>
        </div>
        <div className="px-4">
          <DataTable
            selectableRows
            pagination
            onSelectedRowsChange={handleChange}
            columns={columns}
            data={record}
            fixedHeader
            title="Inmuebles"
            actions={actionsMemo}
          />
        </div>
      </div>
    </div>
  );
}
