import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu } from "./Menu";
import { addContratos } from "./Addregister";
import { useForm } from "react-hook-form";

export function AltaContrato() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  console.log(errors);

  const setContratosvalues = {
    contrato: "",
    arrendador: "",
    canon: "",
    mediopago: "",
    valoriva: "",
    valoradministracion: "",
    incrementoadmon: "",
    incremento: "",
    estado: "",
    fechapago: "",
    fecha: "",
    fechaterminacion: "",
    descripcion: "",
    preaviso: "",
  };

  const [contrato, setContratos] = useState(setContratosvalues);

  const onSubmit = handleSubmit((data) => {
    setContratos(data);
    console.log("data:", data);
    addContratos(data);
    reset();
  });

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8 rounded-lg"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="arrendador"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                />
                {errors.arrendador && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="canon"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Canón
                </label>
                <input
                  type="number"
                  {...register("canon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Canon"
                />
                {errors.canon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="valoriva"
                  className="block text-gray-700 text-xs font-serif font-bold mb-2"
                >
                  Valor IVA
                </label>
                <input
                  type="number"
                  {...register("valoriva", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor IVA"
                />
                {errors.valoriva?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="mediopago"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Medio de pago
                </label>
                <select
                  {...register("mediopago")}
                  value={contrato.mediopago}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="efectivo">Efectivo</option>
                  <option value="consignacion">Consignacion</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fecha"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Estado de pago
                </label>
                <select
                  {...register("estado")}
                  value={contrato.estado}
                  className="shadow w-full h-8 rounded"
                >
                  <option value="yes">Pagado</option>
                  <option value="no">No pagado</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="descripcion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Descripción
                </label>
                <input
                  type="text"
                  {...register("descripcion", {})}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Descripcion"
                />
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="valoradministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Valor de administración
                </label>
                <input
                  type="number"
                  {...register("valoradministracion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Valor de administración"
                />
                {errors.valoradministracion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incrementoadministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  IPC
                </label>
                <input
                  type="text"
                  {...register("incrementoadmon", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="IPC"
                />
                {errors.incrementoadmon?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incremento"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  PUNTOS
                </label>
                <input
                  type="text"
                  {...register("incremento", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="PUNTOS"
                />
                {errors.incremento?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="incrementoadministracion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Preaviso
                </label>
                <input
                  type="text"
                  {...register("preaviso", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Preaviso"
                />
                {errors.preaviso?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha Inicio
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha"
                />
                {errors.fecha && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha Terminación
                </label>
                <input
                  type="date"
                  {...register("fechaterminacion", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha Terminación"
                />
                {errors.fechaterminacion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fechaterminacion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Fecha de pago
                </label>
                <input
                  type="date"
                  {...register("fechapago", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Fecha de pago"
                />
                {errors.fec?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Guardar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Contratos"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
