import { Menu } from "./Menu";
import { uploadFile } from "../firebase";
import { addanexos } from "./Addregister";
import { useEffect, useState } from "react";
import React from "react";
import { db } from "../firebase";
import DataTable from "react-data-table-component";
import { useAuth } from "../context/authContex";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doc,
  deleteDoc,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { Trash2 } from "lucide-react";
import { useForm } from "react-hook-form";

export function Anexos() {
  const { user, logout, loading } = useAuth();
  const navigate = useNavigate();
  const handlelogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const {
    formState: { errors },
    reset,
  } = useForm();
  console.log(errors);
  const [file, setFile] = useState(null);
  const [contrato, setContrato] = useState();
  const [nombre, setNombre] = useState();
  const [url, setUrl] = useState();
  const [id, setId] = useState();
  const [anexos, setAnexos] = useState([]);
  const [record, setRecords] = useState(anexos);

  const handleOnChangeContrato = (e) => {
    const contrato = e.target.value;
    console.log("contrato", contrato);
    setContrato(contrato);
  };

  const handleOnChangeNombre = (e) => {
    const nombre = e.target.value.toUpperCase();
    setNombre(nombre);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = await uploadFile(file);
      const data = {
        nombre: nombre,
        contrato: contrato,
        url: url,
      };

      addanexos(data);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const Viewregister = async () => {
    const q = query(collection(db, "anexos"));
    onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setAnexos(docs);
      setRecords(docs);

      //setData(data);
    });
  };

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.data.nombre,
      sortable: true,
      wrap: false,
    },
    {
      name: "Contrato",
      selector: (row) => row.data.contrato,
      sortable: true,
      wrap: false,
    },
    {
      name: "Url",
      selector: (row) => row.data.url,
      sortable: true,
      wrap: true,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data

    selectedRows.map((row) => {
      console.log("row.id", row.id);
      setContrato(row.data.contrato);
      setId(row.id);
      setUrl(row.data.url);
    });
  };
  const handleOnDelete = async (id) => {
    if (window.confirm("Esta seguro que deseas eliminar el Anexo")) {
      await deleteDoc(doc(db, "anexos", id));
      toast("Anexo eliminado", {
        type: "error",
        autoClose: 1000,
      });
      navigate("/Anexos");
    }
  };

  useEffect(() => {
    Viewregister();
  }, []);

  const handleFilter = (event) => {
    const result = anexos.filter((row) => {
      return row.data.contrato
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRecords(result);
    //setCartera(result);
  };

  return (
    <div>
      <Menu />

      <div className="w-full max-w-lg m-auto">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-10 gap-8 justify-center">
            <input
              type="file"
              className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-white leading-right focus:outline-none  focus:shadow-outline"
              name=""
              id=""
              onChange={(e) => setFile(e.target.files[0])}
            />
            <div className="mb-4">
              <label
                htmlFor="nombre"
                className="block text-white text-xs  font-serif font-bold mb-2"
              >
                Nombre del Anexo
              </label>
              <input
                type="text"
                className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                placeholder="Nombre del Anexo"
                onChange={handleOnChangeNombre}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contrato"
                className="block text-white text-xs  font-serif font-bold mb-2"
              >
                Contrato
              </label>
              <input
                type="text"
                className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                placeholder="Contrato"
                onChange={handleOnChangeContrato}
              />
            </div>
            <button className="w-full bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Upload
            </button>
          </div>
        </form>
      </div>
      <div className="grid grid-cols-12">
        <div className="flex gap-4 justify-start p-2 m-2 col-span-6 ">
          <input
            type="text"
            className="w-96 p-2 focus:outline-none  focus:shadow-outline border rounded "
            onChange={handleFilter}
            placeholder="Buscar por numero contrato"
          />
          <button
            className="cursor-pointer rounded-full  inline-flex flex  items-center bg-red-600 hover:bg-red-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnDelete(id);
            }}
            disabled={id === ""}
          >
            <i>
              <Trash2 />
            </i>
            Eliminar
          </button>
        </div>

        <div className="col-span-6 flex justify-end items-center p-4 ">
          <button
            onClick={handlelogout}
            className="w-44 h-10 bg-teal-600 rounded-full hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
          >
            Cerrar Sesion
          </button>
        </div>
      </div>

      <div className="px-4">
        <DataTable
          title="Anexos"
          selectableRows
          pagination
          onSelectedRowsChange={handleChange}
          columns={columns}
          data={anexos}
          fixedHeader
        />
      </div>
    </div>
  );
}
