import { useEffect, useState } from "react";
import React from "react";
import { Menu } from "./Menu";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import DataTable from "react-data-table-component";
import { useAuth } from "../context/authContex";
import {
  doc,
  deleteDoc,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Trash2, Pencil } from "lucide-react";

import { Plus } from "lucide-react";

export function Clientes() {
  const { user, logout, loading } = useAuth();

  const handlelogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const data = [];
  const [clientes, setClientes] = useState([]);
  const [record, setRecords] = useState(clientes);
  const [Currentid, setCurrentid] = useState("");
  const [id, setId] = useState();
  const [nombre, setNombre] = useState();
  const [direccion, setDireccion] = useState();
  const [correo, setCorreo] = useState();
  const [celular, setCelular] = useState();
  const [fijo, setFijo] = useState();
  const [ciudad, setCiudad] = useState();
  // const [data, setData] = useState();
  const [contrato, setCont] = useState();
  const [documento, setDoc] = useState();

  const navigate = useNavigate(Currentid);
  const Viewregister = async () => {
    const leng = data.length;
    console.log("leng", leng);
    if (leng < 0) {
      console.log("entre porque tengo datos");
      data = [];
    }

    const q = query(collection(db, "clientes"));
    onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setClientes(docs);
      setRecords(docs);

      record.forEach((item) => {
        data.push({ ...item.data });
      });
      //setData(data);
    });
  };

  const handleOnUpdate = (id, docu, con, nom, dir, cor, cel, fij, ciu) => {
    console.log("entre hadleUpdate");
    setCurrentid(id);
    navigate(
      `/UpdateCLientes/id:${id}/doc:${docu}/con:${con}/nom:${nom}/dir:${dir}/cor:${cor}/cel:${cel}/fij:${fij}/ciu:${ciu}`
    );
  };

  useEffect(() => {
    Viewregister();
  }, [Currentid]);

  // funciones para export csv

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(record[0].data);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Clientes.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <button
      className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      onClick={(e) => onExport(data)}
    >
      Export
    </button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  const columns = [
    {
      name: "Contrato",
      selector: (row) => row.data.contrato,
      sortable: true,
      wrap: true,
    },
    {
      name: "Documento",
      selector: (row) => row.data.documento,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.data.nombre,
      sortable: true,
      wrap: true,
    },
    {
      name: "Dirección",
      selector: (row) => row.data.direccion,
      sortable: true,
    },
    { name: "Correo", selector: (row) => row.data.correo, sortable: true },
    {
      name: "Celular",
      selector: (row) => row.data.celular,
      sortable: true,
      wrap: true,
    },
    {
      name: "Numero Fijo",
      selector: (row) => row.data.fijo,
      sortable: true,
      wrap: true,
    },
    {
      name: "Ciudad",
      selector: (row) => row.data.ciudad,
      sortable: true,
      wrap: true,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("handleChange", selectedRows);
    selectedRows.map((row) => {
      setId(row.id);
      setDoc(row.data.documento);
      setCont(row.data.contrato);
      setNombre(row.data.nombre);
      setDireccion(row.data.direccion);
      setCorreo(row.data.correo);
      setCelular(row.data.celular);
      setCiudad(row.data.ciudad);
      setFijo(row.data.fijo);
    });
  };

  const handleOnDelete = async ({ selectedRows }) => {
    if (window.confirm("Esta seguro que deseas eliminar el cliente")) {
      console.log(id);
      await deleteDoc(doc(db, "clientes", id));
      toast("Cliente Eliminado", {
        type: "error",
        autoClose: 1000,
      });
      navigate("/Clientes");
    }
  };

  const handleFilter = (event) => {
    const result = clientes.filter((row) => {
      return row.data.contrato
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRecords(result);
    //setCartera(result);
  };

  return (
    <div>
      <Menu />
      <div className="flex justify-end px-2"></div>

      <div className="mt-5">
        <div className="flex gap-4 p-4 ">
          <div className="mt-5 rounded-full  inline-flex flex gap-2 items-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            <i>
              <Plus />
            </i>
            <Link to={"/AltaCliente"}>Nuevo</Link>
          </div>

          <button
            className="mt-5 cursor-pointer rounded-full inline-flex flex gap-2 items-center bg-red-600 hover:bg-red-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnDelete(id);
            }}
            disabled={id === undefined}
          >
            <i>
              <Trash2 />
            </i>
            Eliminar
          </button>
          <button
            className="mt-5 rounded-full cursor-pointer inline-flex flex gap-2 items-center bg-blue-600 hover:bg-blue-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => {
              handleOnUpdate(
                id,
                documento,
                contrato,
                nombre,
                direccion,
                correo,
                celular,
                fijo,
                ciudad
              );
            }}
            disabled={id === undefined}
          >
            <i>
              <Pencil />
            </i>
            Actualizar
          </button>
        </div>
        <div className="grid grid-cols-12">
          <div className="flex justify-start p-2 m-2 col-span-6 ">
            <input
              type="text"
              className="w-96 p-2 focus:outline-none  focus:shadow-outline border rounded "
              onChange={handleFilter}
              placeholder="Buscar por numero contrato"
            />
          </div>
          <div className="col-span-6 flex justify-end items-center p-4 ">
            <button
              onClick={handlelogout}
              className="w-44 h-10 bg-teal-600 rounded-full hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            >
              Cerrar Sesion
            </button>
          </div>
        </div>

        <div className="px-4">
          <DataTable
            title="Clientes"
            selectableRows
            pagination
            onSelectedRowsChange={handleChange}
            columns={columns}
            data={record}
            fixedHeader
            actions={actionsMemo}
          />
        </div>
      </div>
    </div>
  );
}
