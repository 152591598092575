import { useAuth } from "../context/authContex";
import { Link } from "react-router-dom";

export function Menu() {
  const { user, logout, loading } = useAuth();

  if (loading) return <h1>Loading</h1>;
  return (
    <div className="">
      <div className="w-screen grid bg-white grid-cols-12 ">
        <div className="col-span-9 py-2 px-4 ">
          <ul className="w-full  flex gap-12 ">
            <Link
              className="w-44 flex justify-center text-base hover:underline hover:rounded-full hover:text-teal-600  hover:bg-cyan-950 py-2 font-bold"
              to="/"
            >
              Home
            </Link>
            <Link
              className="w-44 flex justify-center text-base hover:underline hover:rounded-full hover:text-teal-600  hover:bg-cyan-950 py-2 font-bold"
              to="/Inmuebles"
            >
              Inmuebles
            </Link>

            <Link
              className="w-44 flex justify-center text-base hover:underline  hover:rounded-full hover:text-teal-600 hover:bg-cyan-950 p-2 font-bold"
              to="/Clientes"
            >
              Clientes
            </Link>

            <Link
              className="w-44 flex justify-center text-base hover:underline hover:rounded-full hover:text-teal-600  hover:bg-cyan-950 p-2 font-bold"
              to="/Contratos"
            >
              Contratos
            </Link>

            <Link
              className="w-44 flex justify-center text-base hover:underline hover:rounded-full hover:text-teal-600 hover:bg-cyan-950 p-2 font-bold"
              to="/Cartera"
            >
              Cartera
            </Link>
            <Link
              className="w-44 flex justify-center text-base hover:underline hover:rounded-full hover:text-teal-600 hover:bg-cyan-950 p-2 font-bold"
              to="/Anexos"
            >
              Anexos
            </Link>
          </ul>
        </div>
        <div className="col-span-3 flex justify-center pt-2 px-2">
          <div className="inline-flex gap-12 ">
            <div className="flex items-center gap-2">
              <h1 className="text-base mb-4 font-bold"></h1>
              {/* <img src="" alt="" className="mb-4 w-10 h-10" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
