import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export const Addregister = async (data) => {
  const docRef = await addDoc(collection(db, "inmuebles"), { data });
  toast("Inmueble registrado", {
    type: "success",
  });
};

export const addcliente = async (data) => {
  const docRef = await addDoc(collection(db, "clientes"), { data });
  toast("Cliente registrado", {
    type: "success",
  });
};

export const addContratos = async (data) => {
  const docRef = await addDoc(collection(db, "contratos"), { data });
  toast("Contrato registrado", {
    type: "success",
  });
};

export const addcartera = async (data) => {
  const docRef = await addDoc(collection(db, "cartera"), { data });
  toast("Cartera registrada", {
    type: "success",
  });
};

export const addanexos = async (data) => {
  const docRef = await addDoc(collection(db, "anexos"), { data });
  toast("anexo registrado", {
    type: "success",
  });
};
