import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Menu } from "./Menu";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

export function UpdateInmuebles() {
  const { id, ap, dir, ea, ev, es, fc, mat, ma, me, cont, arr, part } =
    useParams();
  const ident = id.substring(3);
  const predio = ap.substring(3);
  const direcion = dir.substring(4);
  const evaluoa = ea.substring(3);
  const evaluov = ev.substring(3);
  const estrato = es.substring(3);
  const ficha = fc.substring(3);
  const matricula = mat.substring(4);
  const matriculaagua = ma.substring(3);
  const matriculaenergia = me.substring(3);
  const contrato = cont.substring(5);
  const arrendador = arr.substring(4);
  const participacion = part.substring(5);
  const navigate = useNavigate(ident);

  console.log("participacion", participacion);

  const setInmueblesvalues = {
    areapredio: predio,
    direccion: direcion,
    evaluoanteior: evaluoa,
    evaluovigente: evaluov,
    estrato: estrato,
    fichacatastral: ficha,
    matricula: matricula,
    matriculaagua: matriculaagua,
    matriculaenergia: matriculaenergia,
    contrato: contrato,
    arrendador: arrendador,
    participacion: participacion,
  };

  //Useform

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [inmuebles, setInmuebles] = useState(setInmueblesvalues);
  const onSubmit = handleSubmit((data) => {
    updateRegister(
      ident,
      data.areapredio,
      data.direccion,
      data.evaluoanteior,
      data.evaluovigente,
      data.estrato,
      data.fichacatastral,
      data.matricula,
      data.matriculaagua,
      data.matriculaenergia,
      data.contrato,
      data.arrendador,
      data.participacion
    );
  });

  const queryregisterid = async (id) => {
    const docRef = doc(db, "inmuebles", ident);
    const docSnap = await getDoc(docRef);
    setInmuebles({ ...docSnap.data() });
  };

  const updateRegister = async (
    id,
    ap,
    dir,
    ea,
    ev,
    es,
    fc,
    mat,
    ma,
    me,
    cont,
    arr,
    part
  ) => {
    const inmuebleRef = doc(db, "inmuebles", id);
    await updateDoc(inmuebleRef, {
      "data.areapredio": ap,
      "data.direccion": dir,
      "data.evaluoanteior": ea,
      "data.evaluovigente": ev,
      "data.estrato": es,
      "data.fichacatastral": fc,
      "data.matricula": mat,
      "data.matriculaagua": ma,
      "data.matriculaenergia": me,
      "data.contrato": cont,
      "data.arrendador": arr,
      "data.participacion": part,
    });
    toast("Inmueble actualizado", {
      type: "success",
    });
    navigate("/Inmuebles");
  };

  useEffect(() => {
    queryregisterid(ident);
    setInmuebles({ ...inmuebles });
  }, [ident]);

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="areapredio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Area del predio
                </label>
                <input
                  type="text"
                  {...register("areapredio", { required: true })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Area del predio"
                  value={inmuebles.areapredio}
                />
                {errors.areapredio && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="direccion"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  {...register("direccion", {
                    required: true,
                    pattern: /^[^#]*$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Dirección"
                  value={inmuebles.direccion}
                />
                {errors.direccion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.direccion?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    No se permite el caracter # reemplacelo por un N
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="evaluoanterio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Evalúo comercial año anterior
                </label>
                <input
                  type="text"
                  {...register("evaluoanteior", {
                    required: true,
                    pattern: /^[0-9.]+$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Evalúo comercial año anterior"
                  value={inmuebles.evaluoanteior}
                />
                {errors.evaluoanteior && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="evaluovigente"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Evalúo comercial año vigente
                </label>
                <input
                  type="text"
                  {...register("evaluovigente", {
                    required: true,
                    pattern: /^[0-9.]+$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Evalúo comercila año vigente"
                  value={inmuebles.evaluovigente}
                />
                {errors.evaluovigente && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="estrato"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Estrato
                </label>
                <input
                  type="number"
                  {...register("estrato", {
                    required: true,
                    maxLength: 1,
                    pattern: /^[0,1,2,3,4,5,6,7]+$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Estrato"
                  value={inmuebles.estrato}
                />
                {errors.estrato?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.estrato?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permite un digito
                  </span>
                )}
                {errors.estrato?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Solo se permiten números del 0 al 7
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Participación"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Participación
                </label>
                <input
                  type="text"
                  {...register("participacion", {
                    required: true,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Participación"
                  value={inmuebles.participacion}
                />
                {errors.participacion?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="fichacatastral"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Ficha catastral
                </label>
                <input
                  type="text"
                  {...register("fichacatastral", {
                    required: true,
                    minLength: 25,
                    maxLength: 25,
                    pattern: /^[0-9A-Z]+$/,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Ficha catastral"
                  value={inmuebles.fichacatastral}
                />
                {errors.fichacatastral?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.fichacatastral?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 25 caracteres
                  </span>
                )}
                {errors.fichacatastral?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener Máximo 25 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matricula"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Matricula
                </label>
                <input
                  type="text"
                  {...register("matricula", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula"
                  value={inmuebles.matricula}
                />
                {errors.matricula?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matricula?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matricula?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matriculaagua"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Matricula Agua
                </label>
                <input
                  type="text"
                  {...register("matriculaagua", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula Agua"
                  value={inmuebles.matriculaagua}
                />
                {errors.matriculaagua?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matriculaagua?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matriculaagua?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="matriculaenergia"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Matricula Energia
                </label>
                <input
                  type="text"
                  {...register("matriculaenergia", {
                    required: true,
                    minLength: 8,
                    maxLength: 9,
                  })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Matricula Energia"
                  value={inmuebles.matriculaenergia}
                />
                {errors.matriculaenergia?.types === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.matriculaenergia?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener mínimo 8 caracteres
                  </span>
                )}
                {errors.matriculaenergia?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener máximo 9 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato")}
                  className="shadow appearance-none border rounded w-fu ll py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                  value={inmuebles.contrato}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="arrendador"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Nombre del arrendador
                </label>
                <input
                  type="text"
                  {...register("arrendador", {
                    required: true,
                  })}
                  className="sshadow appearance-none border rounded w-fu ll py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Arrendador"
                  value={inmuebles.arrendador}
                />
                {errors.arrendador?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Actualizar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Inmuebles"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
