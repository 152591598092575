import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu } from "./Menu";
import { addcliente } from "./Addregister";
import { useForm } from "react-hook-form";

export function AltaCliente() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  console.log(errors);

  const setClientesvalues = {
    contrato: "",
    documento: "",
    nombre: "",
    direccion: "",
    correo: "",
    celular: "",
    fijo: "",
    ciudad: "",
  };

  const [clientes, setClientes] = useState(setClientesvalues);
  const onSubmit = handleSubmit((data) => {
    setClientes(data);
    console.log("data:", data);
    addcliente(data);
    reset();
  });

  return (
    <div>
      <Menu />
      <div className="w-full max-w-lg m-auto">
        <form
          onSubmit={onSubmit}
          className=" bg-white shadow-md px-8 pt-6 pb-8 mt-8 mb-8 rounded-lg"
        >
          <div className="grid grid-cols-12 flex gap-4">
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="contrato"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Contrato
                </label>
                <input
                  type="text"
                  {...register("contrato", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Contrato"
                />
                {errors.contrato && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="documento"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Cédula o NIT
                </label>
                <input
                  type="text"
                  {...register("documento", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Cédula o NIT"
                />
                {errors.documento && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  {...register("nombre", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Nombre Completo"
                />
                {errors.nombre && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="direccion"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  {...register("direccion", {
                    required: true,
                    pattern: /^[^#]*$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Dirección"
                />
                {errors.direccion?.type === "required" && (
                  <span className="text-red-800 text-xs  font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.direccion?.type === "pattern" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    No se permite el caracter # reemplacelo por un N
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <label
                  htmlFor="celular"
                  className="block text-gray-700 text-xs font-bold mb-2"
                >
                  Celular
                </label>
                <input
                  type="number"
                  {...register("celular", {
                    required: true,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Celular"
                />
                {errors.celular?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.celular?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 10 caracteres
                  </span>
                )}
                {errors.celular?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 10 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fijo"
                  className="block text-gray-700 text-xs font-bold mb-2"
                >
                  Número fijo
                </label>
                <input
                  type="number"
                  {...register("fijo", {
                    required: true,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Número fijo"
                />
                {errors.fijo?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
                {errors.fijo?.type === "maxLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 7 caracteres
                  </span>
                )}
                {errors.fijo?.type === "minLength" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Debe tener 7 caracteres
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="fichacatastral"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Ciudad
                </label>
                <input
                  type="text"
                  {...register("ciudad", {
                    required: true,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Ciudad"
                />
                {errors.ciudad?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="evaluoanterio"
                  className="block text-gray-700 text-xs  font-serif font-bold mb-2"
                >
                  Correo
                </label>
                <input
                  type="email"
                  {...register("correo", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  })}
                  className="shadow appearance-none text-xs border rounded w-full py-2 px-3 text-gray-700 leading-right focus:outline-none  focus:shadow-outline"
                  placeholder="Correo electrónico"
                />
                {errors.correo?.type === "required" && (
                  <span className="text-red-800 text-xs font-serif font-bold">
                    Este campo es requerido
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <button className="w-44 bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Guardar
            </button>

            <Link
              className="w-44 flex justify-center bg-teal-600 hover:bg-teal-400 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              to={"/Clientes"}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
